h1 {
  font-size: 1.2rem;

  @media screen and (min-width: 1200px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.8rem;
  }
}

h2 {
  font-size: 1rem;

  @media screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 0.75rem;

  @media screen and (min-width: 1200px) {
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 0.65rem;

  @media screen and (min-width: 1200px) {
    font-size: 0.75rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 0.9rem;
  }
}
