/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #FFFFFF;
  --ion-color-primary-rgb: 100,104,98;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3F6F7B;
  --ion-color-primary-tint: #747772;
  --ion-color-secondary: #606652;
  --ion-color-secondary-rgb: 204,210,183;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #b4b9a1;
  --ion-color-secondary-tint: #d1d7be;
  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #333333;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;
  --ion-color-success: #36484d;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #FFFFFF;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #517881;
  --ion-color-success-tint: #5d99a8;
  --ion-color-warning: #3a663c;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-medium: #666666;
  --ion-color-medium-rgb: 0,0,0;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-font-family: "Lato", sans-serif;
}