/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lato:wght@300&display=swap);
.fs-small {
  font-size: 0.9em;
}

.fs-medium {
  font-size: 1.6em;
}

.fs-large {
  font-size: 2em;
}

.fs-x-large {
  font-size: 5em;
}

.primary-family {
  font-family: "Lato";
}

.secondary-family {
  font-family: "Roboto";
}

.tertiary-family {
  font-family: "Josefin Sans", sans-serif;
}

i.fs-medium {
  font-size: 1.5em;
}

h1 {
  font-size: 1.2rem;
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1400px) {
  h1 {
    font-size: 1.8rem;
  }
}

h2 {
  font-size: 1rem;
}
@media screen and (min-width: 1200px) {
  h2 {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1400px) {
  h2 {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 0.75rem;
}
@media screen and (min-width: 1200px) {
  h3 {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1400px) {
  h3 {
    font-size: 1rem;
  }
}

h4 {
  font-size: 0.65rem;
}
@media screen and (min-width: 1200px) {
  h4 {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 1400px) {
  h4 {
    font-size: 0.9rem;
  }
}

::ng-deep .item-inner {
  border: 0 !important;
}

i {
  margin-right: 5px;
}

.nav-item {
  margin: 10px 0;
  font-weight: bold;
}
@media screen and (min-width: 1200px) {
  .nav-item {
    margin: 10px;
  }
}
.nav-item.active i,
.nav-item.active span {
  color: var(--ion-color-success);
  font-weight: bolder;
}
.nav-item.active span {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.4) !important;
  bottom: 0 !important;
}

ion-alert.custom-alert .alert-wrapper {
  width: 75%;
  max-width: 100% !important;
}
@media screen and (min-width: 1200px) {
  ion-alert.custom-alert .alert-wrapper {
    width: 25% !important;
  }
}
ion-alert.custom-alert .alert-button {
  background-color: var(--ion-color-success);
  color: var(--ion-color-tertiary);
}
ion-alert.custom-alert .alert-head {
  background: var(--ion-color-secondary);
  color: var(--ion-color-tertiary);
}
ion-alert.custom-alert .alert-head h2 {
  color: inherit !important;
}
ion-alert.custom-alert .alert-head h2.alert-title {
  font-size: 1.6em !important;
}
ion-alert.custom-alert .alert-head h2.alert-subtitle {
  font-size: 1.1em !important;
}
ion-alert.custom-alert .alert-message {
  font-size: 1.5em !important;
  font-weight: bold !important;
  padding: 40px !important;
  color: #000000 !important;
  text-align: center !important;
}
ion-alert.custom-alert .alert-button-group {
  justify-content: center !important;
}
ion-alert.custom-alert .alert-button-group .alert-button {
  text-transform: none !important;
  font-size: 1.2em !important;
}
ion-alert.custom-alert .clickable {
  cursor: pointer;
}