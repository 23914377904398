.fs-small {
  font-size: 0.9em;
}

.fs-medium {
  font-size: 1.6em;
}

.fs-large {
  font-size: 2em;
}

.fs-x-large {
  font-size: 5em;
}

.primary-family {
  font-family: 'Lato';
}

.secondary-family {
  font-family: 'Roboto';
}

.tertiary-family {
  font-family: 'Josefin Sans', sans-serif;
}

i.fs-medium {
  font-size: 1.5em;
}
