@import 'fonts';
@import 'headings';

::ng-deep .item-inner {
  border: 0 !important;
}

i {
  margin-right: 5px;
}

.nav-item {
  margin: 10px 0;
  font-weight: bold;

  @media screen and (min-width: 1200px) {
    margin: 10px;
  }

  &.active {
    i,
    span {
      color: var(--ion-color-success);
      font-weight: bolder;
    }

    span {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}

.carousel-caption {
  background-color: rgba($color: #000000, $alpha: 0.4) !important;
  bottom: 0 !important;
}


// alert override
ion-alert.custom-alert {
  .alert-wrapper {
    width: 75%;
    max-width: 100% !important;

    @media screen and (min-width: 1200px) {
      width: 25% !important;
    }
  }

  .alert-button {
    background-color: var(--ion-color-success);
    color: var(--ion-color-tertiary);
  }

  .alert-head {
    background: var(--ion-color-secondary);
    color: var(--ion-color-tertiary);

    h2 {
      color: inherit !important;

      &.alert-title {
        font-size: 1.6em !important;
      }

      &.alert-subtitle {
        font-size: 1.1em !important;
      }
    }
  }

  .alert-message {
    font-size: 1.5em !important;
    font-weight: bold !important;
    padding: 40px !important;
    color: #000000 !important;
    text-align: center !important;
  }

  .alert-button-group {
    justify-content: center !important;

    .alert-button {
      text-transform: none !important;
      font-size: 1.2em !important;
    }
  }

  .clickable {
    cursor: pointer;
  }
}
